import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import labels from "../../../helpers/labels.json";
import { Formik } from "formik";
import { PrimaryCTAButton, SecondaryCTAButton } from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import { CustomInputField } from "../../common/FormInputs";
import useUser from "../../../hooks/useUser";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  table: {
    backgroundColor: "transparent",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.INPUT_LABEL,
    fontSize: 10,
    paddingBottom: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "openSans-Regular, sans-serif",
  },
}));

function BulkUpload({ id, setTriggerCaseCall, title, caseDetails }) {
  const classes = useStyles();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { authSetting } = useUser();
  const initialState = {
    extraField: "",
    value: "",
  };
  const [extraFields, setExtraFields] = useState([]);

  useEffect(() => {
    if (Object.keys(caseDetails?.arbitrationAgreementFields).length !== 0) {
      setExtraFields(
        Object.entries(caseDetails?.arbitrationAgreementFields).map(
          ([key, value]) => ({
            extraField: key,
            value: value,
          }),
        ),
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Update bulkuplod..." });
      const arbitrationAgreementFields = values.extra_fields_items.reduce(
        (acc, item) => {
          acc[item.extraField] = item.value;
          return acc;
        },
        {},
      );
      const response = await CaseService.arbAgreementDetails(id, {
        arbitrationAgreementFields,
      });
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        setTriggerCaseCall(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const addItems = (values, setFieldValue) => {
    const new_items = [
      ...values?.extra_fields_items,
      {
        extraField: "",
        value: "",
      },
    ];
    setFieldValue("extra_fields_items", new_items);
  };

  const deleteItems = (index, values, setFieldValue) => {
    if (values?.extra_fields_items?.length) {
      const filtered = values?.extra_fields_items.filter(
        (v, idx) => idx !== index,
      );
      setFieldValue("extra_fields_items", filtered);
    }
  };

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading style={{ marginLeft: 24 }}>{title}</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Formik
            initialValues={{
              extra_fields_items: extraFields?.length
                ? extraFields?.map((item) => ({
                    ...item,
                    extraField: item?.extraField,
                    value: item?.value,
                  }))
                : [initialState] || [initialState],
            }}
            enableReinitialize
            validateOnBlur
            // validationSchema={AddAgentValidationSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <BigTable>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: 153,
                          }}
                          className={classes.tableHead}
                        >
                          Extra Fields
                        </TableCell>
                        <TableCell
                          style={{
                            width: 153,
                          }}
                          className={classes.tableHead}
                        >
                          Text
                        </TableCell>
                        <TableCell
                          style={{ width: 10 }}
                          className={classes.tableHead}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="mis-dashboard">
                      {values?.extra_fields_items?.map((item, index) => (
                        <TableRow className={"table-row"} key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ position: "relative" }}
                          >
                            <CustomSelect
                              menuItemValues={
                                authSetting?.noticeGenerationFields
                              }
                              key={index}
                              value={item.extraField}
                              name={`extra_fields_items[${index}].extraField`}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ position: "relative" }}
                          >
                            <CustomInputField
                              key={index}
                              className={"input-white"}
                              value={item.value}
                              name={`extra_fields_items[${index}].value`}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ position: "relative" }}
                          >
                            {values.extra_fields_items.length > 1 && (
                              <DeleteIcon
                                onClick={() =>
                                  deleteItems(index, values, setFieldValue)
                                }
                                src={require("../../../assets/images/removeIcon.svg")}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </BigTable>
                <ButtonWrapper>
                  <SecondaryCTAButton
                    onClick={() => addItems(values, setFieldValue)}
                    style={{ paddingLeft: 0 }}
                  >
                    + Add Extra Fields
                  </SecondaryCTAButton>
                </ButtonWrapper>
                <PrimaryCTAButton
                  style={{ width: "110px", marginTop: 16, padding: "5px 5px" }}
                  onClick={handleSubmit}
                >
                  {labels["buttons.save"]}
                </PrimaryCTAButton>
              </form>
            )}
          </Formik>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default BulkUpload;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 200px;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

const BigTable = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    display: block;
  }
`;

const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;
